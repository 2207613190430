import * as React from 'react';
import IndiaBasePageLayout from "../../layouts/IndiaBasePageLayout";
import {Section} from "../../components/Section";
import SLink from "../../components/soul/link/SLink";
import {AnchorType} from "../../components/soul/link/LinkTypes";
import {Icons} from "../../components/soul/icon/IconsSet";
import Grid from "../../components/soul/grid/Grid";
import {GridDirection} from "../../components/soul/grid/GridDirection";
import {GridDensity} from "../../components/soul/grid/GridDensity";
import GridItem from "../../components/soul/grid/GridItem";
import {fullSpanOnMobile, hideSpanOnDesktop, hideSpanOnMobile} from "../../components/soul/grid/GridItemSpan";
import {StaticImage} from "gatsby-plugin-image";

/**
 * @author ESTECO CSA Team <csa@esteco.com>
 */

const IndexPage: React.FC = () => {
    return (
        <IndiaBasePageLayout location="/india">
            <Section>
                <div className="soul-content soul-space-stack-bottom-l">
                    <h2 className="soul-font-size-xl">Thank you for making the ESTECO UM23 India a great success!</h2>
                    <p>116 attendees, 14 speakers and 3 partners joined us in Bengaluru on 23 August 2023.
                        The event provided a perfect mix of presentations on ESTECO Technology and related customer stories.</p>
                    <p>We had a rich agenda of presentations from different industries and academic experts, outlining the
                        most advanced application scenarios for the ESTECO Technologies.</p>
                    <p>ESTECO experts and executives took the stage to deliver inspiring talks about our vision and to
                        give some insights related to the product roadmap. We were very glad to connect with our
                        Indian customers and partners in such a thriving business environment.</p>
                    <p>Market leaders like Cummins, ADA, CABS, Mahindra, Tata presented the business challenges and how
                        ESTECO Technologies enabled them to overcome these challenges.<br/>
                        PhD research associates from IIT, Madras also presented unique areas of research assisted by optimization
                        technologies. Overall, it was a nice gathering back in person and a great learning opportunity for attendees
                        from various organizations.</p>
                    <p>Thank you again for taking part in the UM23 India!</p>
                </div>
            </Section>
            <Section>
                <Grid direction={GridDirection.HORIZONTAL} density={GridDensity.COMFORTABLE} wrap={true}>
                    <GridItem colSpan={6} responsive={[fullSpanOnMobile]}>
                        <StaticImage src="../../assets/img/umindia-1.jpg" alt="UM 23 Speakers" />
                    </GridItem>
                    <GridItem colSpan={6} responsive={[fullSpanOnMobile]}>
                        <StaticImage src="../../assets/img/umindia-2.jpg" alt="UM 23 Speakers" />
                    </GridItem>
                </Grid>
            </Section>
            <Section>
                <Grid direction={GridDirection.HORIZONTAL} density={GridDensity.COMFORTABLE} wrap={true}>
                    <GridItem colSpan={6}>
                        <h2 className="soul-font-size-2-xl soul-space-stack-bottom-s">23 August</h2>
                        <p className="soul-space-stack-bottom-m">A full day of inspiring talks and technical presentations.</p>
                        <p>A unique opportunity to meet ESTECO representatives and Indian partners.</p>
                    </GridItem>
                    <GridItem>
                        <h2 className="soul-font-size-2-xl soul-space-stack-bottom-s">Reasons to join</h2>
                        <div className="soul-content">
                            <ul>
                                <li>Meet the ESTECO community.</li>
                                <li>Discuss about features and trends.</li>
                                <li>See modeFRONTIER and VOLTA in action.</li>
                            </ul>
                        </div>
                    </GridItem>
                </Grid>
            </Section>
            <Section>
                <Grid direction={GridDirection.HORIZONTAL} density={GridDensity.COMFORTABLE} wrap={true}>
                    <GridItem colSpan={6} responsive={[fullSpanOnMobile]}>
                        <div className="soul-content">
                            <h2 className="soul-font-size-xl">The venue</h2>
                            <p className="soul-space-stack-both-m">Welcomhotel Bengaluru is a LEED Platinum & LEED Zero
                                Carbon Certified Hotel, making it the fourth hotel in the world to achieve this recognition.</p>
                            <p><span className="h-text-bold">WelcomHotels by ITC</span><br/>
                                46, Richmond Rd, Victoria Layout, Bengaluru,<br/>
                                Karnataka 560025, India
                            </p>
                            <SLink to="https://www.itchotels.com/in/en/welcomhotelbengaluru" linkType={AnchorType.DEFAULT} icon={Icons.FORWARD} iconAfter={true}>
                                Visit website
                            </SLink>
                        </div>
                    </GridItem>
                    <GridItem colSpan={6} responsive={[fullSpanOnMobile]}>
                        <div className="esteco-full-width esteco-map-container" dangerouslySetInnerHTML={{
                            __html: `
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.0475465998975!2d77.6144934!3d12.9688094!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae168210251fdf%3A0xc66dd8a58a2f9346!2sWelcomhotel%20by%20ITC%20Hotels%2C%20Richmond%20Road%2C%20Bengaluru-%20LEED%C2%AE%20Platinum%20and%20Zero%20Carbon%20Certified%20Hotel%20in%20the%20Heart%20of%20Bengaluru!5e0!3m2!1sit!2sit!4v1687858178471!5m2!1sit!2sit" width="600" height="350" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            `}}></div>
                    </GridItem>
                </Grid>
            </Section>
        </IndiaBasePageLayout>
    );
};
export default IndexPage;
